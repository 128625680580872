var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "productWrap" },
    [
      _c(
        "div",
        { staticStyle: { display: "flex", "margin-bottom": "20px" } },
        [
          _c(
            "a-select",
            {
              staticStyle: { width: "180px", height: "40px" },
              model: {
                value: _vm.chooseProduct,
                callback: function ($$v) {
                  _vm.chooseProduct = $$v
                },
                expression: "chooseProduct",
              },
            },
            [
              _c("a-select-option", { attrs: { value: "3" } }, [
                _vm._v(" 全部产品 "),
              ]),
              _c("a-select-option", { attrs: { value: "0" } }, [
                _vm._v(" 产品1 "),
              ]),
              _c("a-select-option", { attrs: { value: "1" } }, [
                _vm._v(" 产品2 "),
              ]),
              _c("a-select-option", { attrs: { value: "2" } }, [
                _vm._v(" 产品3 "),
              ]),
            ],
            1
          ),
          _c(
            "ul",
            { staticStyle: { margin: "0 40px", "text-align": "center" } },
            [
              _c("li", [_vm._v("总定位次数")]),
              _c("li", { staticStyle: { margin: "14px 0", color: "red" } }, [
                _vm._v(" 1000 "),
              ]),
              _c(
                "li",
                [
                  _c("a-button", { attrs: { type: "primary" } }, [
                    _vm._v(" 购买定位套餐 "),
                  ]),
                ],
                1
              ),
            ]
          ),
          _c(
            "ul",
            { staticStyle: { margin: "0 40px", "text-align": "center" } },
            [
              _c("li", [_vm._v("剩余定位次数")]),
              _c("li", { staticStyle: { margin: "14px 0", color: "red" } }, [
                _vm._v(" 888 "),
              ]),
              _c(
                "li",
                [
                  _c(
                    "a-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.isVisible = true
                        },
                      },
                    },
                    [_vm._v(" 查看使用详情 ")]
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _c("a-table", {
        staticStyle: { "margin-bottom": "20px" },
        attrs: {
          locale: { emptyText: "暂无数据" },
          "table-layout": "auto",
          columns: _vm.columns,
          pagination: false,
          "row-key": "id",
          "data-source": _vm.data,
        },
        scopedSlots: _vm._u([
          {
            key: "operation",
            fn: function (text, record) {
              return [
                _c(
                  "a",
                  {
                    attrs: { href: "javascript:void(0);" },
                    on: {
                      click: function ($event) {
                        return _vm.onLook(record)
                      },
                    },
                  },
                  [_vm._v("查看位置")]
                ),
              ]
            },
          },
        ]),
      }),
      _c("Pagination", { attrs: { count: 3 } }),
      _c(
        "a-modal",
        {
          attrs: {
            title: "查看使用详情",
            visible: _vm.isVisible,
            footer: null,
          },
          on: {
            cancel: function ($event) {
              _vm.isVisible = false
            },
          },
        },
        [
          _c(
            "div",
            { staticStyle: { "margin-bottom": "20px" } },
            [
              _c("a-input-search", {
                attrs: { placeholder: "请输入产品名称" },
              }),
            ],
            1
          ),
          _c("a-table", {
            attrs: {
              columns: _vm.detailColumns,
              "data-source": _vm.detailData,
            },
            on: { change: _vm.onChange },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }