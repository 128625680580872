<template>
    <div class="productWrap">
        <!-- <p>
            <span class="productTitle">LBS定位</span>
        </p> -->
        <div style="display: flex; margin-bottom: 20px">
            <a-select v-model="chooseProduct" style="width: 180px; height: 40px">
                <a-select-option value="3">
                    全部产品
                </a-select-option>
                <a-select-option value="0">
                    产品1
                </a-select-option>
                <a-select-option value="1">
                    产品2
                </a-select-option>
                <a-select-option value="2">
                    产品3
                </a-select-option>
            </a-select>
            <ul style="margin: 0 40px; text-align: center">
                <li>总定位次数</li>
                <li style="margin: 14px 0; color: red">
                    1000
                </li>
                <li>
                    <a-button type="primary">
                        购买定位套餐
                    </a-button>
                </li>
            </ul>
            <ul style="margin: 0 40px; text-align: center">
                <li>剩余定位次数</li>
                <li style="margin: 14px 0; color: red">
                    888
                </li>
                <li>
                    <a-button @click="isVisible = true">
                        查看使用详情
                    </a-button>
                </li>
            </ul>
        </div>
        <a-table style="margin-bottom: 20px"
                 :locale="{emptyText: '暂无数据'}"
                 table-layout="auto" :columns="columns" :pagination="false" row-key="id" :data-source="data">
            <template slot="operation" slot-scope="text, record">
                <a href="javascript:void(0);" @click="onLook(record)">查看位置</a>
            </template>
        </a-table>
        <Pagination :count="3" />
        <!-- 查看使用详情弹窗 -->
        <a-modal
            title="查看使用详情"
            :visible="isVisible"
            :footer="null"
            @cancel="isVisible = false">
            <div style="margin-bottom: 20px">
                <a-input-search placeholder="请输入产品名称" />
            </div>
            <a-table :columns="detailColumns" :data-source="detailData" @change="onChange" />
        </a-modal>
    </div>
</template>

<script>
import Pagination from "@/components/Pagination"
const columns = [
    {
        title: '设备名称',
        dataIndex: 'name',
        key: 'name'
    },
    {
        title: '产品名称',
        dataIndex: 'productName',
        key: 'productName'
    },
    {
        title: '定位时间',
        dataIndex: 'time',
        key: 'time'
    },
    {
        title: '操作',
        width: '100px',
        scopedSlots: { customRender: 'operation' }
    },
]
const data = [
    {
        id: 0,
        name: 'skdjis',
        productName: 'sdefers',
        time: '2021-1-1'
    },
    {
        id: 1,
        name: 'skdjis',
        productName: 'sdefers',
        time: '2021-1-1'
    },
    {
        id: 2,
        name: 'skdjis',
        productName: 'sdefers',
        time: '2021-1-1'
    }
]
const detailColumns = [
    {
        title: '产品名称',
        dataIndex: 'name'
    },
    {
        title: '定位次数',
        dataIndex: 'age',
        defaultSortOrder: 'descend',
        sorter: (a, b) => a.age - b.age,
    }
]

const detailData = [
    {
        key: '1',
        name: 'John Brown',
        age: 32
    },
    {
        key: '2',
        name: 'Jim Green',
        age: 42
    },
    {
        key: '3',
        name: 'Joe Black',
        age: 32
    },
    {
        key: '4',
        name: 'Jim Red',
        age: 32
    },
]

function onChange(pagination, filters, sorter) {
    console.log('params', pagination, filters, sorter)
}
export default {
    name: "LBSPosition",
    components: {
        Pagination
    },
    data() {
        return {
            // 当前选择的产品
            chooseProduct: '3',
            columns,
            data,
            // 查看使用详情弹窗是否显示
            isVisible: false,
            detailColumns,
            detailData
        }
    },
    methods: {
        // 查看位置操作
        onLook() {
            this.$router.push('/lbs-place/equip-site')
        },
        onChange
    }
}
</script>

<style lang="scss" scoped>
.productWrap {
    height: 96%;
    margin: 2% 40px;
    .productTitle {
        font-size: 20px;
        font-weight: bold;
        margin-right: 100px;
    }

    ::v-deep .ant-pagination {
        text-align: right;
        margin-bottom: 20px;
    }
    ::v-deep .ant-pagination-total-text {
        float: left;
    }
}
</style>
